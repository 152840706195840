export default {
  data() {
    return {
      baseApiUrl: '/api/v4/',
      mobileApiUrl: '/mobile-api/v1/api/',
      publicApiUrl: '/public-api/v1/api/',
      controllerUrl: '/',
      clientsCollectionUrl: '/client_collections/',
    };
  },
  computed: {
    accountUrl: function () {
      return this.baseApiUrl + 'account_page/';
    },
    adminStatsUrl: function () {
      return this.baseApiUrl + 'admin_stats/';
    },
    adminDevtoolsUrl: function () {
      return this.baseApiUrl + 'admin_devtools/';
    },
    adminMarketplaceUrl: function () {
      return this.baseApiUrl + `admin_marketplace/`;
    },
    aiUrl: function () {
      return this.baseApiUrl + 'ai/';
    },
    assetUrl: function () {
      return this.baseApiUrl + 'asset/';
    },
    authUrl: function () {
      return this.baseApiUrl + 'auth/';
    },
    billingUrl: function () {
      return this.baseApiUrl + 'billing/';
    },
    clientsPageUrl: function () {
      return this.baseApiUrl + 'clients_page/';
    },
    elementTagUrl: function () {
      return this.baseApiUrl + 'element_tag/';
    },
    clientProgramUrl: function () {
      return this.baseApiUrl + 'client_programs/';
    },
    communityUrl: function () {
      return this.baseApiUrl + 'community/';
    },
    customNotificationUrl: function () {
      return this.baseApiUrl + 'custom_notification/';
    },
    fileUrl: function () {
      return this.baseApiUrl + 'file/';
    },
    tagsUrl: function () {
      return this.baseApiUrl + 'tags/';
    },
    impersonateUrl: function () {
      return this.controllerUrl + 'impersonate/';
    },
    memberUrl: function () {
      return this.baseApiUrl + 'member/';
    },
    unsplashUrl: function () {
      return this.baseApiUrl + 'unsplash/';
    },
    messageUrl: function () {
      return this.baseApiUrl + 'message/';
    },
    exerciseMessageUrl: function () {
      return this.baseApiUrl + 'exercise_message/';
    },
    metricsUrl: function () {
      return this.baseApiUrl + 'metrics/';
    },
    metricAutocompleteUrl() {
      return this.clientsCollectionUrl + 'metrics_autocomplete';
    },
    mobileClientTags: function () {
      return this.mobileApiUrl + 'coach/current-logged/clients/tags';
    },
    organizationSettingsUrl: function () {
      return this.baseApiUrl + 'organization_settings/';
    },
    organizationUrl: function () {
      return this.baseApiUrl + 'organization/';
    },
    programUrl: function () {
      return this.baseApiUrl + 'program/';
    },
    libraryProgramUrl: function () {
      return this.baseApiUrl + 'library_program/';
    },
    registerUrl: function () {
      return 'registrations/';
    },
    reportUrl: function () {
      return this.baseApiUrl + 'report/';
    },
    resourceUrl: function () {
      return this.baseApiUrl + 'resource/';
    },
    sessionUrl: function () {
      return this.baseApiUrl + 'session/';
    },
    userUrl: function () {
      return this.baseApiUrl + 'user/';
    },
    adminUrl: function () {
      return this.baseApiUrl + 'admin/';
    },
    uploadFileUrl: function () {
      return this.baseApiUrl + 'file/upload_file';
    },
    marketUrl: function () {
      return this.baseApiUrl + `marketplace/`;
    },
    measurementsUrl: function () {
      return this.baseApiUrl + 'measurements/';
    },
    notificationUrl: function () {
      return this.baseApiUrl + 'notifications/';
    },
    nutritionUrl: function () {
      return this.baseApiUrl + 'nutrition/';
    },
    cronometerUrl: function () {
      return this.publicApiUrl + 'cronometer/';
    },
    vendorKeysUrl: function () {
      return this.baseApiUrl + 'vendor-keys/';
    },
    clientsUrl: function () {
      return this.baseApiUrl + 'clients/';
    },
    bulkClientUrl: function () {
      return this.baseApiUrl + 'bulk_client_add/';
    },
    clientCollection: function () {
      return this.baseApiUrl + 'client_collections/';
    },
    vaultUrl: function () {
      return this.baseApiUrl + 'vault/';
    },
    stripeUrl: function () {
      return this.baseApiUrl + 'stripe_connect/';
    },
    themeUrl: function() {
      return this.baseApiUrl + 'themes/';
    }
  },
  methods: {},
};
