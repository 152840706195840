<template>
  <div class="content-margins">
    <div
      class="login-form smaller-form flex flex-col items-center justify-center"
    >
      <div v-if="!requestedOrganization">
        <img
          :src="require(`images/cc_login.png`)"
          alt="Coach Catalyst"
          class="login-logo"
        />
      </div>
      <div v-else>
        <img
          :src="requestedOrganization.logo"
          alt="Coach Catalyst"
          class="login-logo"
        />
      </div>
      <p class="login-p">Sign in to your account</p>
      <div v-if="!requestedOrganization">
        <label>Or</label
        ><label class="login-start-trail" @click="startTrialClick">
          start your {{ trialDays }}-day trial</label
        >
      </div>

      <form
        novalidate="novalidate"
        class="simple_form new_user"
        id="new_user"
        action="/auth/sign_in"
        accept-charset="UTF-8"
        method="post"
      >
        <input name="utf8" type="hidden" value="✓" />
        <input
          type="hidden"
          name="authenticity_token"
          value="souGDZlQRnP5Vj2aQVd/u7cmlYco9Xhyo4ahMpaZmd+kKkZ4X18GljxG+bYZ6QgN5VrREfAgLAXVU4Ldkh1o1g=="
        />

        <fieldset>
          <div class="input email shadow-none">
            <input
              tabindex="1"
              autofocus="autofocus"
              class="string email login-email"
              type="email"
              v-model="email"
              placeholder="Email address"
            />
          </div>
          <div class="input password shadow-none">
            <input
              tabindex="2"
              class="password login-password"
              type="password"
              placeholder="Password"
              v-model="password"
              @keydown="keyPress"
            />
          </div>
          <div class="login-footer">
            <input type="checkbox" v-model="remember_me" /><label class="ml-2"
              >Remember Me</label
            >
            <a href="/auth/password/new">
              <label
                :style="[
                  requestedOrganization &&
                  requestedOrganization.theme &&
                  requestedOrganization.theme.primary_color
                    ? { color: requestedOrganization.theme.primary_color }
                    : { color: '#3AB373' },
                ]"
                class="login-forgot"
                >Forgot your password?</label
              >
            </a>
            <svg
              width="14"
              height="16"
              class="login-svg"
              viewBox="0 0 14 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2 7V5C2 2.23858 4.23858 0 7 0C9.76142 0 12 2.23858 12 5V7C13.1046 7 14 7.89543 14 9V14C14 15.1046 13.1046 16 12 16H2C0.89543 16 0 15.1046 0 14V9C0 7.89543 0.895431 7 2 7ZM10 5V7H4V5C4 3.34315 5.34315 2 7 2C8.65685 2 10 3.34315 10 5Z"
                fill="#fff"
              />
            </svg>
            <input
              :style="[
                requestedOrganization &&
                requestedOrganization.theme &&
                requestedOrganization.theme.primary_color
                  ? {
                      'background-color':
                        requestedOrganization.theme.primary_color,
                    }
                  : { 'background-color': '#3AB373' },
              ]"
              type="button"
              @click="submit"
              value="Sign in"
              tabindex="4"
              class="btn button submit-button"
              data-disable-with="Log in"
            />
          </div>
        </fieldset>
      </form>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import ApiUrls from '../../api-url';
  import { mapGetters, mapState } from 'vuex';
  import getters from '../../stores/login-store/getters/login-getters';
  import AuthenticationMixin from '../../authentication-mixin';

  export default {
    mixins: [ApiUrls, AuthenticationMixin],
    data() {
      return {
        email: '',
        password: '',
        remember_me: false,
      };
    },
    async created() {
      await this.getData();
      console.log(this.requestedOrganization);
    },
    computed: {
      ...mapState(['trialAmount', 'trialDays', 'requestedOrganization']),
      ...mapGetters(['LOGIN_FORM', 'SIGN_UP_CREATE_ACCOUNT']),
    },
    beforeCreate: function () {
      document.body.style.backgroundColor = '#F9FAFB';
    },
    methods: {
      async getData() {
        document.title = `New Device/Session | Coach Catalyst`;
      },
      startTrialClick() {
        this.$router.push({ name: 'SignupPage' });
      },
      keyPress(e) {
        if (e.keyCode === 13) {
          this.submit();
        }
      },
      async submit() {
        let formData = new FormData();
        formData.append('email', this.email);
        formData.append('password', this.password);
        formData.append('remember_me', this.remember_me);

        try {
          const response = await axios.post(`${this.authUrl}log_in`, formData);
          if (response.data.success) {
            window.location.href = response.data.redirect_uri;
          } else {
            this.$toast.show(response.data.result, this.toasted_options.error);
          }
        } catch (error) {
          this.$toast.show(
            'Your account has been archived by your coach.  Please contact them to reactivate and gain access.',
            this.toasted_options.error
          );
        }
      },
    },
  };
</script>

<style lang="scss"></style>
